/**
 * @author chang, hauser PrivaSphere confidential, (c) 2024 - 2024 all rights reserved
 *
 * https://git.privasphere.com/privasphere/privalope/-/issues/133
 *
 */
import { LoggerFactory } from "myd-commons";
import { Validator } from "./Validator";
import { translations } from "../languages/LangueFormat";
import { Utils } from "../Utils";
//this class implements https://wiki.privasphere.com/index.php/TF02_Formular_erstellen
export class EmailValidator extends Validator {
    constructor() {
        super();
        this.showMainError = this.showMainError.bind(this);
        this.showMainNotif = this.showMainNotif.bind(this);
        this.logger = LoggerFactory.getLogger(EmailValidator.name);
    }
    // Method to validate user input when used on onChange
    validateInputOnChange(inputField, divID, multiple = false, what = "") {
        this.validateInputOnChangeWiDbg(inputField, divID, multiple, what);
    }
    validateInputOnChangeWiDbg(pInputField, divID, multiple, dbg) {
        console.log(dbg + ": in 'validateInputOnChangeWiDbg' " +
            Utils.getCurrentTimeString());
        this.inputField = pInputField;
        let notParentDiv = document.getElementById(divID);
        if (notParentDiv) {
            this.parentElementDom = notParentDiv;
        }
        else {
            if (pInputField && pInputField.parentElement) {
                this.parentElementDom = pInputField.parentElement;
            }
            else {
                console.trace(dbg + ": validateInputOnChangeWiDbg 'pInputField.parentElement' " +
                    " does not exist " + Utils.getCurrentTimeString() + " ");
            }
        }
        try {
            this.removeErrorMessage(dbg);
            this.removeNotifMessage(dbg);
        }
        catch (e) {
            console.trace(dbg + ": problem with 'remove*Message' " + e + " " +
                Utils.getCurrentTimeString() + " "); //new Date().toISOString()
        }
        if (!pInputField || '' === pInputField.value || '' === pInputField.value.trim() || !pInputField.parentElement) {
            return;
        }
        const inpOrig = pInputField.value;
        const initialLgth = inpOrig.length;
        this.inputFieldNotNull();
        const errStart = 'The input is "' + inpOrig + '". ' + dbg;
        let fromStr = this.inputField.value;
        this.logger.info(errStart);
        this.cleanUpInput(this.inputField, multiple);
        fromStr = this.inputField.value;
        const xlatEnHash = translations["en"];
        const xlatEn = "***unknownTranslation english *** ";
        if (null == xlatEnHash) {
            this.logger.error("null == xlatEnHash");
            console.trace(dbg + " null == xlatEnHash " + Utils.getCurrentTimeString() + " "); //new Date().toISOString()
        }
        let xlatCurr = "***unknownTranslation " + currentLanguageTS
            + " ***";
        const xlatCurrLangHash = translations[currentLanguageTS];
        if (null == xlatCurrLangHash) {
            this.logger.error("null == xlatCurrLangHash");
            console.trace(dbg + " null == xlatCurrLangHash " + Utils.getCurrentTimeString() + " "); //new Date().toISOString()
        }
        let err = "";
        if (multiple) {
            if (-1 != fromStr.indexOf(';')) {
                err = "ts_wrongEmailSeparators";
                this.showError(xlatCurrLangHash[err]);
                this.logger.warn(errStart + " " + xlatEnHash[err]);
            }
        }
        else {
            if (-1 != fromStr.indexOf(',')) {
                err = "ts_emailmustonlyone";
                this.showError(xlatCurrLangHash[err]);
                this.logger.warn(errStart + " " + xlatEnHash[err] + " !multiple");
            }
        }
        let splitByComma = fromStr.split(",");
        for (let email of splitByComma) {
            if (-1 == email.indexOf('@')) {
                err = "ts_emailmusthaveat";
                this.showError(xlatCurrLangHash[err]);
                this.logger.warn(errStart + " " + xlatEnHash[err]);
            }
            let emailTrim = email.trim();
            if (null == emailTrim.match(/\S+\w\.\w{2,}$/)) {
                err = "ts_emailmusthavedomain";
                this.showError(xlatCurrLangHash[err]);
                this.logger.warn(errStart + " " + xlatEnHash[err]);
            }
        }
    }
    cleanUpInput(inputField, multiple = false) {
        const original0 = inputField.value;
        const prevLgth0 = original0.length;
        this.notifMessage(inputField, /\s/g, "", "ts_WhitespaceRemoved");
        const original1 = inputField.value;
        const prevLgth1 = original1.length;
        this.notifMessage(inputField, /[.]+/g, ".", "ts_MultipleDecimalDelimitersReducedToOne");
        const original2 = inputField.value;
        const prevLgth2 = original2.length;
        this.notifMessage(inputField, /^[^a-zA-Z0-9.@]+|[^a-zA-Z0-9.@]+$/g, "", "ts_leadtrailRemoved");
        if (multiple) {
            const original = inputField.value;
            const prevLgth = original.length;
            const res = Utils.replaceAndCount(original, ';', ',');
            let changed = "";
            if (null != res && 0 < res.count) {
                changed = res.modifiedString;
                this.notifMessageChanged(changed, original, prevLgth + res.count, "ts_wrongEmailSeparators" //"mistaken separator"
                , inputField, "replaced");
            }
        }
    }
    showMainError() {
        var _a;
        const error = this.parser.getErrorByProperty("errors.email");
        let errorstr = error.err.replace("{0}", (_a = this.inputField) === null || _a === void 0 ? void 0 : _a.value);
        this.showMainErrorInfo(errorstr, (error === null || error === void 0 ? void 0 : error.property) + " " + (error === null || error === void 0 ? void 0 : error.constant), error === null || error === void 0 ? void 0 : error.errNo);
    }
    showError(errMsg) {
        this.showErrorInfo(errMsg, this.showMainError);
    }
    showMainNotif() {
        const notif = this.parser.getNotificationByProperty("email.formatted");
        this.showMainNotifInfo(notif.err);
    }
    showNotif(infoMsg) {
        this.showNotifInfo(infoMsg, this.showMainNotif);
        // Hide notif after 10 seconds
        setTimeout(() => {
            var _a, _b, _c;
            const infoMessage = (_b = (_a = this.inputField) === null || _a === void 0 ? void 0 : _a.parentNode) === null || _b === void 0 ? void 0 : _b.querySelector("#" + this.notifContainerID + ((_c = this.inputField) === null || _c === void 0 ? void 0 : _c.id));
            if (infoMessage) {
                this.removeNotifMessage();
            }
        }, 10000);
    }
}
export function validateEmailInput(inputField, divID, multiple = false, what = "") {
    const emailValidator = new EmailValidator();
    emailValidator.validateInputOnChange(inputField, divID, multiple, what);
}
